import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { PayoutsOverviewComponent } from 'src/app/components/payouts/payouts-overview/payouts-overview.component';
import { ModalComponent } from '../../ui-components/modal/modal.component';
import { PayoutCycleAmountChangeEditComponent } from '../../cycle/payout-cycle-amount-change-edit/payout-cycle-amount-change-edit.component';
import {
  CommissionCycle,
  CreditAccount,
  PayoutMerged,
} from 'src/app/shared/models';
import { AccountBalancesDetailComponent } from '../../account/account-balances-detail/account-balances-detail.component';

@Component({
  selector: 'app-payouts-overview-modal',
  standalone: true,
  imports: [
    AccountBalancesDetailComponent,
    ModalComponent,
    PayoutCycleAmountChangeEditComponent,
    PayoutsOverviewComponent,
  ],
  templateUrl: './payouts-overview-modal.component.html',
  styleUrl: './payouts-overview-modal.component.scss',
})
export class PayoutsOverviewModalComponent implements AfterViewInit {
  private cdr = inject(ChangeDetectorRef);

  @Input() openWith?: {
    userId: string;
    identifierGroupId?: string;
    policyId?: string;
    agencyFilterType?: string;
    showAllCycles?: boolean;
    cycleId?: string;
    cycles?: CommissionCycle[];
    editAmount?: {
      payoutsMerged?: PayoutMerged;
      amountChanges?: PayoutMerged;
      agencyId?: string;
    };
    balances?: any;
    accounts?: CreditAccount[];
  };
  @Output() valueChanged = new EventEmitter<any>();
  @Output() modalDismissedFired = new EventEmitter<boolean>();

  @ViewChild('modal') modal?: ModalComponent;

  policyId?: string;
  identifierGroupId?: string;
  userId?: string;
  agencyFilterType?: string;
  showAllCycles?: boolean;
  cycleId?: string;
  cycles?: CommissionCycle[];
  editAmount?: {
    payoutsMerged?: PayoutMerged;
    amountChanges?: PayoutMerged;
    agencyId?: string;
  };
  balances?: any;
  accounts?: CreditAccount[];

  ngAfterViewInit() {
    if (this.openWith) {
      console.log('openWith', this.openWith);
      this.open(
        this.openWith.userId,
        this.openWith.identifierGroupId,
        this.openWith.policyId,
        this.openWith.agencyFilterType,
        this.openWith.showAllCycles,
        this.openWith.cycleId,
        this.openWith.cycles,
        this.openWith.editAmount,
        this.openWith.balances,
        this.openWith.accounts,
      );
      this.cdr.detectChanges();
    }
  }

  open(
    userId: string,
    identifierGroupId?: string,
    policyId?: string,
    agencyFilterType?: string,
    showAllCycles?: boolean,
    cycleId?: string,
    cycles?: CommissionCycle[],
    editAmount?: {
      payoutsMerged?: PayoutMerged;
      amountChanges?: PayoutMerged;
      agencyId?: string;
    },
    balances?: any,
    accounts?: CreditAccount[],
  ) {
    console.log('open payout overview', {
      userId,
      identifierGroupId,
      policyId,
      agencyFilterType,
      showAllCycles,
      cycleId,
      cycles,
      editAmount,
      balances,
      accounts,
    });
    this.policyId = policyId;
    this.identifierGroupId = identifierGroupId;
    this.userId = userId;
    this.agencyFilterType = agencyFilterType;
    this.showAllCycles = showAllCycles;
    this.cycleId = cycleId;
    this.cycles = cycles;
    this.editAmount = editAmount;
    this.balances = balances;
    this.accounts = accounts;
    this.modal?.show();
    this.cdr.detectChanges();
  }
}
