import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  PLATFORM_ID,
  importProvidersFrom,
  inject,
  isDevMode,
} from '@angular/core';
import { Router, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideServiceWorker } from '@angular/service-worker';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { getWindow } from 'ssr-window';
import { isPlatformServer } from '@angular/common';

import * as fromAccount from './shared/store/account/+state/account.reducer';
import * as fromAgent from './shared/store/agent/+state/agent.reducer';
import * as fromAgency from './shared/store/agency/+state/agency.reducer';
import * as fromCommission from './shared/store/commission/+state/commission.reducer';
import * as fromCommissionCycle from './shared/store/commission-cycle/+state/commission-cycle.reducer';
import * as fromEmployee from './shared/store/employee/+state/employee.reducer';
import * as fromPayout from './shared/store/payout/+state/payout.reducer';
import * as fromPermissionGranted from './shared/store/permission-granted/+state/permission-granted.reducer';
import { AccountEffects } from './shared/store/account/+state/account.effects';
import { AgentEffects } from './shared/store/agent/+state/agent.effects';
import { AgencyEffects } from './shared/store/agency/+state/agency.effects';
import { CommissionEffects } from './shared/store/commission/+state/commission.effects';
import { CommissionCycleEffects } from './shared/store/commission-cycle/+state/commission-cycle.effects';
import { EmployeeEffects } from './shared/store/employee/+state/employee.effects';
import { PayoutEffects } from './shared/store/payout/+state/payout.effects';
import { PermissionGrantedEffects } from './shared/store/permission-granted/+state/permission-granted.effects';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';

const provideSentryErrorHandler = (opts = {}) => {
  return {
    provide: ErrorHandler,
    useValue: createErrorHandler(opts),
  };
};

const provideSentryTraceService = () => {
  return {
    provide: TraceService,
    deps: [Router],
  };
};

const provideSentryInitializer = () => {
  return {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [TraceService],
    multi: true,
  };
};

const window = getWindow();
if (window.location.hostname === 'localhost') {
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() =>
      initializeFirestore(getApp(), { ignoreUndefinedProperties: true })
    ),
    provideFunctions(() => getFunctions(undefined, 'us-east1')),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    //@ts-expect-error - Initialize only on client
    provideAppCheck(() => {
      const platformId = inject(PLATFORM_ID);
      if (isPlatformServer(platformId)) {
        return;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.firebase.appCheckRecaptchaSiteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true,
      });
    }),
    importProvidersFrom([ScreenTrackingService, UserTrackingService]),
    provideStore({
      [fromAccount.accountFeatureKey]: fromAccount.reducer,
      [fromAgent.agentFeatureKey]: fromAgent.reducer,
      [fromAgency.agencyFeatureKey]: fromAgency.reducer,
      [fromCommission.commissionFeatureKey]: fromCommission.reducer,
      [fromCommissionCycle.commissionCycleFeatureKey]:
        fromCommissionCycle.reducer,
      [fromEmployee.employeeFeatureKey]: fromEmployee.reducer,
      [fromPayout.payoutFeatureKey]: fromPayout.reducer,
      [fromPermissionGranted.permissionGrantedFeatureKey]:
        fromPermissionGranted.reducer,
    }),
    provideEffects([
      AccountEffects,
      AgentEffects,
      AgencyEffects,
      CommissionEffects,
      CommissionCycleEffects,
      EmployeeEffects,
      PayoutEffects,
      PermissionGrantedEffects,
    ]),
    provideStoreDevtools({
      connectInZone: true,
    }),
    provideStoreDevtools({
      connectInZone: true,
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideSentryErrorHandler({
      showDialog: false,
    }),
    provideSentryTraceService(),
    provideSentryInitializer(),
    provideHttpClient(),
    provideAnimations(),
  ],
};
