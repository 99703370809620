import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ThemeDemoBorderComponent } from './shared/components/theme/theme-demo-border/theme-demo-border.component';
import { ThemeDevComponent } from './shared/components/theme/theme-dev/theme-dev.component';

const agencyIdRoutes = [
  {
    path: 'accounts',
    loadChildren: () =>
      import('./components/accounts/accounts.routes').then(
        r => r.accountsRoutes
      ),
  },
  {
    path: 'payouts',
    loadChildren: () =>
      import('./components/payouts/payout.routes').then(r => r.payoutRoutes),
  },
];

export const routes: Routes = [
  {
    path: '',
    component: environment.isDemo
      ? ThemeDemoBorderComponent
      : ThemeDevComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/dashboard/dashboard.routes').then(
            r => r.dashboardRoutes
          ),
      },
      {
        path: 'sso',
        loadComponent: () =>
          import('./components/auth/sso/sso.component').then(
            m => m.SsoComponent
          ),
      },
      {
        path: 'agency',
        loadChildren: () =>
          import('./components/agency/agency.routes').then(r => r.agencyRoutes),
      },
      {
        path: 'agent',
        loadChildren: () =>
          import('./components/agent/agent.routes').then(r => r.agentRoutes),
      },

      {
        path: 'book-of-business',
        loadChildren: () =>
          import('./components/book-of-business/book-of-business.routes').then(
            r => r.bookOfBusinessRoutes
          ),
      },
      {
        path: 'employee',
        loadChildren: () =>
          import('./components/employee/employee.routes').then(
            r => r.employeeRoutes
          ),
      },
      ...agencyIdRoutes,
      {
        path: ':agencyId',
        children: agencyIdRoutes,
      },
    ],
  },
];
