<app-modal-history
  [identifierGroupId]="payout?.identifiers?.identifierGroupId"
  [wide]="true"
  #modal
  (dismissed)="modalDismissed()"
>
  @if (payout) {
    <h3>Payout</h3>

    <div class="grid gap-4">
      <div class="grid md:grid-cols-2 gap-4">
        <div class="card">
          <div class="card-body">
            <table class="table">
              <tbody>
                <tr></tr>

                @if (payout.policyId) {
                  <tr>
                    <td>Policy Id</td>
                    <td>{{ payout.policyId }}</td>
                  </tr>
                }

                @if (payout.type) {
                  <tr>
                    <td>Type</td>
                    <td>
                      @if (commissionTypeMap[payout.type]) {
                        {{ commissionTypeMap[payout.type].title }}
                      } @else {
                        {{ payout.type }}
                      }
                    </td>
                  </tr>
                }
                @if (payout.payoutType) {
                  <tr>
                    <td>Payout Type</td>
                    <td>
                      @if (payoutTypeMap[payout.payoutType]) {
                        {{ payoutTypeMap[payout.payoutType].title }}
                      }
                    </td>
                  </tr>
                }
                <!-- @if (payout.origin) {
                  <tr>
                    <td>Origin</td>
                    <td>
                      @if (commissionOriginMap[payout.origin]) {
                        {{ commissionOriginMap[payout.origin].title }}
                      } @else {
                        {{ payout.origin }}
                      }
                    </td>
                  </tr>
                } -->

                @if (payout.effectiveDate) {
                  <tr>
                    <td>Effective Date</td>
                    <td>
                      {{ payout.effectiveDate.timestamp | date: 'medium' }}
                    </td>
                  </tr>
                }
                @if (payout.statementDate) {
                  <tr>
                    <td>Statement Date</td>
                    <td>
                      {{ payout.statementDate.timestamp | date: 'medium' }}
                    </td>
                  </tr>
                }
                @if (payout.terminationDate) {
                  <tr>
                    <td>Termination Date</td>
                    <td>
                      {{ payout.terminationDate.timestamp | date: 'medium' }}
                    </td>
                  </tr>
                }
                @if (payout.paymentDate) {
                  <tr>
                    <td>Payment Date</td>
                    <td>{{ payout.paymentDate.timestamp | date: 'medium' }}</td>
                  </tr>
                }
                @if (payout.signatureDate) {
                  <tr>
                    <td>Signature Date</td>
                    <td>
                      {{ payout.signatureDate.timestamp | date: 'medium' }}
                    </td>
                  </tr>
                }

                @if (payout.state) {
                  <tr>
                    <td>State</td>
                    <td>{{ payout.state }}</td>
                  </tr>
                }
                @if (payout.level) {
                  <tr>
                    <td>Level</td>
                    <td>{{ payout.level }}</td>
                  </tr>
                }

                @if (payout.commissionAmount) {
                  <tr>
                    <td>Total Commission</td>
                    <td>{{ payout.commissionAmount | currency }}</td>
                  </tr>
                }
                @if (payout.carrier) {
                  <tr>
                    <td>Carrier</td>
                    <td>
                      @if (carrierMap[payout.carrier.id]; as carrier) {
                        <div class="flex flex-row gap-2 items-center">
                          <div class="w-20 max-h-14 object-contain object-left">
                            <img [src]="carrier.profileImage" />
                          </div>
                          <div>
                            <p>{{ carrier.name }}</p>
                          </div>
                        </div>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div class="grid gap-4">
          @if (payout.consumer) {
            <div class="card">
              <div class="card-body">
                <div class="card-title">Consumer</div>

                <table class="table">
                  <tbody>
                    @if (payout.consumer.id) {
                      <tr>
                        <td>Id</td>
                        <td>
                          {{ payout.consumer.id }}
                        </td>
                      </tr>
                    }
                    @if (payout.consumer.firstName) {
                      <tr>
                        <td>Name</td>
                        <td>
                          {{ payout.consumer.firstName }}
                          {{ payout.consumer.lastName }}
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }

          @if (payout.agent) {
            <div class="card">
              <div class="card-body">
                <div class="card-title">Agent</div>

                <table class="table">
                  <tbody>
                    @if (payout.agent.firstName) {
                      <tr>
                        <td>Name</td>
                        <td>
                          {{ payout.agent.firstName }}
                          {{ payout.agent.lastName }}
                        </td>
                      </tr>
                    } @else {
                      @if (
                        payout.agent.id &&
                          userCacheService.cache[payout.agent.id];
                        as user
                      ) {
                        <tr>
                          <td>Name</td>
                          <td>
                            {{ user.firstName }}
                            {{ user.lastName }}
                          </td>
                        </tr>
                      }
                    }
                    @if (payout.agent.npn) {
                      <tr>
                        <td>NPN</td>
                        <td>
                          {{ payout.agent.npn }}
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }

          @if (payout.transaction) {
            <div class="card">
              <div class="card-body">
                <div class="card-title">Transaction</div>

                <table class="table">
                  <tbody>
                    <tr>
                      <td>Amount</td>
                      <td>{{ payout.transaction.amount | currency }}</td>
                    </tr>
                    <tr>
                      <td>Account</td>
                      <td>
                        @if (payout.transaction.account?.id) {
                          <app-account-card
                            [accountId]="payout.transaction.account?.id"
                            [owner]="payout.transaction.account?.owner"
                          ></app-account-card>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>
                        {{ payout.note }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      </div>

      @if (payout.identifiedResult && payout.identifiedResult.df1) {
        <!-- <div>
          <h3>Identified Payout</h3>

          <app-df-card [df]="payout.identifiedResult.df1"></app-df-card>
        </div> -->
      }

      @if (
        payout.executive &&
        payout.executive.aggregatedExecutiveRuleCommissions &&
        payout.executive.aggregatedExecutiveRuleCommissions.executiveLadder
      ) {
        <div>
          <div class="flex gap-2 flex-row items-center">
            <h3>Payout Receivers</h3>
            @if (payout.custom && payout.custom.isCustom) {
              <span class="badge badge-error"> Custom Payout </span>
            }
            @if (
              payout.payout?.appliedAdvancedPayoutRules &&
              payout.payout?.appliedAdvancedPayoutRules?.from
            ) {
              @if (
                payout.payout?.appliedAdvancedPayoutRules?.from | keyvalue;
                as appliedAdvancedPayoutRulesList
              ) {
                @if (appliedAdvancedPayoutRulesList.length > 0) {
                  <span class="badge badge-warning">
                    Advanced Payout Rule Applied
                  </span>
                }
              }
            }
          </div>

          <div class="stats shadow">
            @if (ltv !== undefined && ltv !== null) {
              <div class="stat">
                <div class="stat-title">LTV for You</div>
                <div class="stat-value">
                  {{ ltv | currency }}
                </div>
              </div>
            }
            @if (ltvTotal !== undefined && ltvTotal !== null) {
              <div class="stat">
                <div class="stat-title">LTV Total</div>
                <div class="stat-value">{{ ltvTotal | currency }}</div>
              </div>
            }
          </div>

          <!-- <div class="flex gap-2">
            @if (ltv !== undefined || ltv !== null) {
              <div>
                <h4>LTV for You</h4>
                <h2>{{ ltv | currency }}</h2>
              </div>
            }
            @if (ltvTotal !== undefined || ltvTotal !== null) {
              <div>
                <h4>LTV Total</h4>
                <h2>{{ ltvTotal | currency }}</h2>
              </div>
            }
          </div> -->

          <table class="table">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Level</th>
                <th class="text-left">Payout</th>
              </tr>
            </thead>
            <tbody>
              @for (
                receiverId of payout.executive
                  .aggregatedExecutiveRuleCommissions.executiveLadder | reverse;
                track receiverId
              ) {
                <tr>
                  <td>
                    <app-user-card [userId]="receiverId"></app-user-card>
                  </td>
                  <td>
                    @if (
                      payout.executive &&
                      payout.executive.aggregatedExecutiveRuleCommissions
                        .executiveTierLadder
                    ) {
                      @for (
                        level of payout.executive
                          .aggregatedExecutiveRuleCommissions
                          .executiveTierLadder[receiverId].tierLevels;
                        track level
                      ) {
                        @if (level.payoutDefinition) {
                          <p>{{ level.payoutDefinition.levelName }}</p>
                        }
                      }
                    }
                  </td>
                  <td>
                    @if (payout.payout && payout.payout.byUser) {
                      @if (
                        payout.identifiedResult &&
                        payout.identifiedResult.pennyTolerance &&
                        payout.identifiedResult.pennyToleranceOwner ===
                          receiverId
                      ) {
                        {{
                          payout.payout.byUser[receiverId] +
                            payout.identifiedResult.pennyTolerance | currency
                        }}
                        <br />
                        <app-tooltip class="mt-2" text="Tolerance Amount">
                          <span class="badge badge-error badge-outline gap-2">
                            <fa-icon [icon]="faNotEqual"></fa-icon>
                            {{
                              payout.identifiedResult.pennyTolerance * -1
                                | currency
                            }}
                          </span>
                        </app-tooltip>
                      } @else {
                        {{ payout.payout.byUser[receiverId] | currency }}
                      }
                    }
                  </td>
                </tr>
              }
              <tr>
                <td><b>Total</b></td>
                <td></td>
                <td>
                  <b>
                    @if (payout.payout) {
                      {{ payout.payout.total | currency }}
                    }
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      } @else if (
        payout.executive &&
        payout.executive.aggregatedExecutiveRule &&
        payout.executive.aggregatedExecutiveRule.executiveLadder
      ) {
        <div>
          <div class="flex gap-2 flex-row items-center">
            <h3>Payout Receivers</h3>
            @if (payout.custom && payout.custom.isCustom) {
              <span class="badge badge-error"> Custom Payout </span>
            }
            @if (
              payout.payout?.appliedAdvancedPayoutRules &&
              payout.payout?.appliedAdvancedPayoutRules?.from
            ) {
              @if (
                payout.payout?.appliedAdvancedPayoutRules?.from | keyvalue;
                as appliedAdvancedPayoutRulesList
              ) {
                @if (appliedAdvancedPayoutRulesList.length > 0) {
                  <span class="badge badge-warning">
                    Advanced Payout Rule Applied
                  </span>
                }
              }
            }
          </div>

          <div class="stats shadow">
            @if (ltv !== undefined && ltv !== null) {
              <div class="stat">
                <div class="stat-title">LTV for You</div>
                <div class="stat-value">
                  {{ ltv | currency }}
                </div>
              </div>
            }
            @if (ltvTotal !== undefined && ltvTotal !== null) {
              <div class="stat">
                <div class="stat-title">LTV Total</div>
                <div class="stat-value">{{ ltvTotal | currency }}</div>
              </div>
            }
          </div>

          <!-- <div class="flex gap-2">
            @if (ltv !== undefined || ltv !== null) {
              <div>
                <h4>LTV for You</h4>
                <h2>{{ ltv | currency }}</h2>
              </div>
            }
            @if (ltvTotal !== undefined || ltvTotal !== null) {
              <div>
                <h4>LTV Total</h4>
                <h2>{{ ltvTotal | currency }}</h2>
              </div>
            }
          </div> -->

          <table class="table">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Payout</th>
              </tr>
            </thead>
            <tbody>
              @for (
                receiverId of payout.executive.aggregatedExecutiveRule
                  .executiveLadder | reverse;
                track receiverId
              ) {
                <tr>
                  <td>
                    <app-user-card [userId]="receiverId"></app-user-card>
                  </td>
                  <td>
                    @if (payout.payout && payout.payout.byUser) {
                      @if (
                        payout.identifiedResult &&
                        payout.identifiedResult.pennyTolerance &&
                        payout.identifiedResult.pennyToleranceOwner ===
                          receiverId
                      ) {
                        {{
                          payout.payout.byUser[receiverId] +
                            payout.identifiedResult.pennyTolerance | currency
                        }}
                        <br />
                        <app-tooltip class="mt-2" text="Tolerance Amount">
                          <span class="badge badge-error badge-outline gap-2">
                            <fa-icon [icon]="faNotEqual"></fa-icon>
                            {{
                              payout.identifiedResult.pennyTolerance * -1
                                | currency
                            }}
                          </span>
                        </app-tooltip>
                      } @else {
                        {{ payout.payout.byUser[receiverId] | currency }}
                      }
                    }
                  </td>
                </tr>
              }
              <tr>
                <td><b>Total</b></td>
                <td>
                  <b>
                    @if (payout.payout) {
                      {{ payout.payout.total | currency }}
                    }
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

      @if (relatedPayouts) {
        <div>
          <h3>Related Payouts</h3>

          @if (relatedPayouts.length > 0) {
            <div class="card">
              <div class="card-body">
                <div class="overflow-x-auto">
                  <table class="table table-sm table-pin-cols">
                    <thead>
                      <tr>
                        <td>Plan Type</td>
                        <td>Rate</td>
                        <td>Signature Date</td>
                        <td>Effective Month</td>
                        <td>Type</td>
                        <td>Payout</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      @for (d of relatedPayouts; track d) {
                        <tr>
                          <td>
                            @if (
                              d.identifiedResult &&
                              d.identifiedResult.df1 &&
                              d.identifiedResult.df1.columnDetail &&
                              d.identifiedResult.df1.columnDetail.planType &&
                              planTypeMap[
                                d.identifiedResult.df1.columnDetail.planType
                              ]
                            ) {
                              {{
                                planTypeMap[
                                  d.identifiedResult.df1.columnDetail.planType
                                ].title
                              }}
                            } @else {
                              Unknown
                            }
                          </td>
                          <td>
                            @if (
                              d.identifiedResult &&
                              d.identifiedResult.df1 &&
                              d.identifiedResult.df1.columnDetail &&
                              d.identifiedResult.df1.columnDetail.rate &&
                              commissionRateMap[
                                d.identifiedResult.df1.columnDetail.rate
                              ]
                            ) {
                              {{
                                commissionRateMap[
                                  d.identifiedResult.df1.columnDetail.rate
                                ].title
                              }}
                            } @else {
                              Unknown
                            }
                          </td>

                          <td>
                            @if (d.signatureDate) {
                              {{ d.signatureDate.timestamp | date: 'medium' }}
                            }
                          </td>
                          <td>
                            @if (d.effectiveDate && d.effectiveDate.month) {
                              {{ monthMap[d.effectiveDate.month] }}
                            }
                          </td>
                          <td>
                            @if (d.type && commissionTypeMap[d.type]) {
                              {{ commissionTypeMap[d.type].title }}
                            }
                          </td>
                          <td>{{ d.payout?.total | currency }}</td>

                          <td>
                            <app-tooltip text="Show Payout">
                              <button
                                type="button"
                                class="btn btn-circle btn-ghost"
                                (click)="showPayout(d)"
                              >
                                <fa-icon [icon]="faEye"></fa-icon>
                              </button>
                            </app-tooltip>
                          </td>
                          <!-- <td>
                      <div class="flex flex-row gap-2 items-center rounded-full pr-2">
                        <div class="avatar" *ngIf="d?.teamLeader?.profileImage">
                          <div class="w-10 rounded-full">
                            <img [src]="d.teamLeader.profileImage" />
                          </div>
                        </div>
                        <div
                          class="w-10 h-10 flex items-center justify-center shrink-0"
                          *ngIf="!d?.profileImage">
                          <fa-icon [icon]="faUserTie"></fa-icon>
                        </div>
                        <div>
                          <p>
                            {{ d.teamLeader?.firstName }} {{ d.teamLeader?.lastName }}
                          </p>
                        </div>
                      </div>
                    </td> -->
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          } @else {
            <p>No related payouts found</p>
          }
        </div>
      }
    </div>
  } @else {
    <app-loading></app-loading>
  }
</app-modal-history>

<!-- <app-payouts-detail-modal #payoutsDetailModal></app-payouts-detail-modal> -->
<!-- @if (payout) {
  <app-payouts-detail-modal #payoutsDetailModal></app-payouts-detail-modal>
} -->
