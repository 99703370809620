import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPersonWalkingArrowLoopLeft } from '@fortawesome/pro-regular-svg-icons';
import { UserType } from 'src/app/shared/models';
import { AuthService, UserInfo } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../../../package.json';
import { getWindow } from 'ssr-window';

@Component({
  selector: 'app-theme-demo-border',
  templateUrl: './theme-demo-border.component.html',
  styleUrls: ['./theme-demo-border.component.scss'],
  standalone: true,
  imports: [FaIconComponent, RouterOutlet],
})
export class ThemeDemoBorderComponent implements OnInit {
  private authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);

  faPersonWalkingArrowLoopLeft = faPersonWalkingArrowLoopLeft;
  demo = environment.isDemo;

  openerWindow: any;

  UserType = UserType;

  user: UserInfo | null = null;

  version = environment.production
    ? packageJson.version
    : environment.devVersion;

  ngOnInit() {
    const window = getWindow();
    this.openerWindow = window.opener;

    if (environment.isDemo) {
      this.authService.user$.subscribe((user) => {
        this.user = user;
        this.cdr.detectChanges();
      });
    }
  }

  switchRole() {
    const window = getWindow();
    this.authService.logout();
    console.log('switchRole');
    window.opener.postMessage('close', '*');

    this.router.navigate(['/demo']);
  }
}
