import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import pack from '../../package.json';
import { CheckUpdateService } from './shared/services/update/check-update.service';
import { ModalContainerComponent } from './shared/components/ui-components/modal-container/modal-container.component';
import {
  ModalContainerService,
  ShowModalContainer,
} from './shared/services/modal-container.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [AsyncPipe, CommonModule, ModalContainerComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private updateService = inject(CheckUpdateService);
  private modalContainerService = inject(ModalContainerService);

  title = 'enrollhere-commissions';

  version = pack.version;

  showModals$: Observable<ShowModalContainer[]>;

  constructor() {
    this.showModals$ = this.modalContainerService.showModals$;
  }
}
