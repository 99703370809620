<h2><fa-icon [icon]="faMoneyBillTransfer"></fa-icon> Amount Changes</h2>

<div class="grid md:grid-cols-2 gap-4">
  <div class="stats">
    <div class="stat">
      <div class="stat-title">Commission Total</div>
      <div class="stat-value">
        {{
          payoutsMerged?.payout?.total | currency: 'USD' : 'symbol' : '1.2-2'
        }}
      </div>
    </div>
  </div>

  @if (
    (payoutsMerged && payoutsMerged.payout && payoutsMerged.payout.total
      ? payoutsMerged.payout.total
      : 0) +
      (amountChanges &&
      amountChanges &&
      amountChanges.payout &&
      amountChanges.payout.total
        ? amountChanges.payout.total
        : 0);
    as totalPayout
  ) {
    <div
      class="stats text-white"
      [class.bg-success]="totalPayout > 0"
      [class.bg-error]="totalPayout < 0">
      <div class="stat">
        <div class="stat-title text-white">Payout After Changes</div>
        <div class="stat-value">
          {{ totalPayout ?? 0 | currency: 'USD' : 'symbol' : '1.2-2' }}
        </div>
      </div>
    </div>
  } @else {
    <div class="stats">
      <div class="stat">
        <div class="stat-title">Payout After Changes</div>
        <div class="stat-value">
          {{ 0 | currency: 'USD' : 'symbol' : '1.2-2' }}
        </div>
      </div>
    </div>
  }

  @if (accounts$ | async; as accounts) {
    @for (
      account of accounts.accounts | sortAlphabetically;
      track account.id
    ) {
      <div class="card">
        <div class="card-body">
          <div class="card-title">Balance {{ account.name }}</div>
          <div class="flex flex-col gap-2">
            <!-- <h3>
              {{
                payoutsMerged?.accountBalance?.byAccount?.[account.id]?.balance
                  | currency
              }}
            </h3> -->

            <table class="table">
              <tbody>
                <tr>
                  <td>Starting Balance</td>
                  <td>
                    @if (
                      payoutsMerged &&
                      payoutsMerged.accountBalance &&
                      payoutsMerged.accountBalance.byAccount &&
                      payoutsMerged.accountBalance.byAccount[account.id] &&
                      payoutsMerged.accountBalance.byAccount[account.id].balance
                    ) {
                      <h3
                        [class.text-success]="
                          (payoutsMerged.accountBalance.byAccount[account.id]
                            .balance ?? 0) > 0
                        "
                        [class.text-error]="
                          (payoutsMerged.accountBalance.byAccount[account.id]
                            .balance ?? 0) < 0
                        ">
                        {{
                          payoutsMerged.accountBalance.byAccount[account.id]
                            .balance ?? 0 | currency: 'USD' : 'symbol' : '1.2-2'
                        }}
                      </h3>
                    } @else {
                      <h3>
                        {{ 0 | currency: 'USD' : 'symbol' : '1.2-2' }}
                      </h3>
                    }
                  </td>
                </tr>

                <tr>
                  <td>Amount Changes</td>
                  <td>
                    <label
                      class="input input-bordered flex items-center gap-2"
                      [class.border-success]="
                        (amountChanges?.accountBalance?.byAccount?.[account.id]
                          ?.balance ?? 0) > 0
                      "
                      [class.border-error]="
                        (amountChanges?.accountBalance?.byAccount?.[account.id]
                          ?.balance ?? 0) < 0
                      ">
                      <fa-icon [icon]="faDollarSign"></fa-icon>
                      <input
                        id="total"
                        name="total"
                        class="w-full"
                        [ngModel]="
                          amountChanges?.accountBalance?.byAccount?.[account.id]
                            ?.balance
                        "
                        (ngModelChange)="updateAmountChange(account.id, $event)"
                        type="number"
                        placeholder="Change Amount" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>Final Balance</td>
                  <td>
                    @if (
                      (payoutsMerged?.accountBalance?.byAccount?.[account.id]
                        ?.balance ?? 0) +
                        (amountChanges?.accountBalance?.byAccount?.[account.id]
                          ?.balance ?? 0);
                      as finalAmount
                    ) {
                      <h3
                        [class.text-success]="finalAmount > 0"
                        [class.text-error]="finalAmount < 0">
                        {{ finalAmount | currency: 'USD' : 'symbol' : '1.2-2' }}
                      </h3>
                    } @else {
                      <h3>
                        {{ 0 | currency: 'USD' : 'symbol' : '1.2-2' }}
                      </h3>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    }
  }
</div>
