import { ApplicationRef, Injectable, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs/internal/observable/interval';
import { concatWith, filter, first } from 'rxjs/operators';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root',
})
export class CheckUpdateService {
  private toastService = inject(ToastService);

  constructor() {
    const appRef = inject(ApplicationRef);
    const updates = inject<SwUpdate>(SwUpdate);

    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(
      first(isStable => isStable === true)
    );
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = appIsStable$.pipe(
      concatWith(everySixHours$)
    );

    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        console.log(
          updateFound
            ? 'A new version is available.'
            : 'Already on the latest version.'
        );
      } catch (err: any) {
        console.log('Failed to check for updates:', err.message);
      }
    });

    updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(() => {
        this.toastService
          .prompt('Update available', 'Do you want to reload?', 'Yes')
          .then(result => {
            if (result) {
              document.location.reload();
            }
          });
      });

    updates.unrecoverable.subscribe(event => {
      this.toastService.error(
        'An error occurred that we cannot recover from:\n' + event.reason,
        'Please reload the page.'
      );
    });
  }
}
