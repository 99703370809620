import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PayoutActions } from './payout.actions';
import {
  DocumentData,
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Commission } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class PayoutEffects {
  private actions$ = inject(Actions);
  private firestore = inject(Firestore);

  loadPayouts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutActions.loadPayouts),
      switchMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(
              this.firestore,
              'agencies',
              agencyId,
              'commissionPayouts',
            ),
            (snapshot) => {
              const payouts = snapshot.docs.map((doc) =>
                Commission.fromJSON({ ...doc.data(), id: doc.id }),
              );
              subscriber.next(PayoutActions.loadPayoutsSuccess({ payouts }));
            },
            (error) => {
              subscriber.next(PayoutActions.loadPayoutsFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Payout API] Load Payouts Error', error }),
          ),
        );
      }),
    );
  });

  loadPayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutActions.loadPayout),
      switchMap(({ payoutId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'agencies',
              agencyId,
              'commissionPayouts',
              payoutId,
            ),
            (snapshot) => {
              const payout = Commission.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(PayoutActions.loadPayoutSuccess({ payout }));
            },
            (error) => {
              subscriber.next(PayoutActions.loadPayoutFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addPayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutActions.addPayout),
      mergeMap(async ({ payout, agencyId }) => {
        try {
          const docRef = await addDoc(
            collection(
              this.firestore,
              'agencies',
              agencyId,
              'commissionPayouts',
            ),
            Commission.toJSON(payout),
          );
          return PayoutActions.addPayoutSuccess({
            payout: { ...payout, id: docRef.id },
            agencyId,
          }); // return new payout with id
        } catch (error) {
          return PayoutActions.addPayoutFailure({ error });
        }
      }),
    );
  });

  removePayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutActions.removePayout),
      mergeMap(async ({ payoutId, agencyId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'agencies',
              agencyId,
              'commissionPayouts',
              payoutId,
            ),
          );
          return PayoutActions.removePayoutSuccess({ payoutId, agencyId }); // return removed payout's id
        } catch (error) {
          return PayoutActions.removePayoutFailure({ error });
        }
      }),
    );
  });

  updatePayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutActions.updatePayout),
      mergeMap(async ({ payoutId, agencyId, payout }) => {
        try {
          await setDoc(
            doc(
              this.firestore,
              'agencies',
              agencyId,
              'commissionPayouts',
              payoutId,
            ),
            Commission.toJSON(payout as Commission) as DocumentData,
            {
              merge: true,
            },
          );
          return PayoutActions.updatePayoutSuccess({
            payoutId,
            agencyId,
            payout,
          }); // return updated payout's id and changes
        } catch (error) {
          return PayoutActions.updatePayoutFailure({ error });
        }
      }),
    );
  });
}
