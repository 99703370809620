@if (showModal) {
  @switch (showModal.component) {
    @case (ShowModalComponents.POLICY_UPDATE) {
      <app-policy-update-edit-modal
        #policyUpdatesEditModal
        [openWith]="showModal.data"
        (retryClicked)="fireEvent('retry', $event)"
        (modalDismissedFired)="
          modalDismissed(showModal.component, $event)
        "></app-policy-update-edit-modal>
    }
    @case (ShowModalComponents.COMMISSION) {
      <app-commissions-edit-modal
        #commissionsEditModal
        [openWith]="showModal.data"
        (retryClicked)="fireEvent('retry', $event)"
        (modalDismissedFired)="
          modalDismissed(showModal.component, $event)
        "></app-commissions-edit-modal>
    }
    @case (ShowModalComponents.ENROLLMENT) {
      <app-enrollment-modal
        #enrollmentModal
        [openWith]="showModal.data"
        (modalDismissedFired)="
          modalDismissed(showModal.component, $event)
        "></app-enrollment-modal>
    }
    @case (ShowModalComponents.PAYOUT) {
      <app-payouts-detail-modal
        #payoutsDetailModal
        [openWith]="showModal.data"
        (modalDismissedFired)="
          modalDismissed(showModal.component, $event)
        "></app-payouts-detail-modal>
    }

    @case (ShowModalComponents.PAYOUT_OVERVIEW) {
      <app-payouts-overview-modal
        #payoutsDetailModal
        [openWith]="showModal.data"
        (valueChanged)="fireEvent('valueChanged', $event)"
        (modalDismissedFired)="
          modalDismissed(showModal.component, $event)
        "></app-payouts-overview-modal>
    }
  }
}
