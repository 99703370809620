<div class="p-4 pb-16 relative">
  <router-outlet></router-outlet>
</div>

@if (demo) {
  <div
    class="fixed pointer-events-none left-0 right-0 top-0 bottom-10 z-[99] bg-transparent rounded-2xl m-2 outline-primary"
  style="outline-width: 4rem; outline-style: solid"></div>
  <!-- [class.outline-secondary]="user?.type === UserType.CONSUMER"
  [class.outline-accent]="user?.type === UserType.AGENT"
  [class.outline-success]="user?.type === UserType.LEADSELLER"
    [class.outline-primary]="
      !user || !user.type || user.type === UserType.AGENCY
    "
  [class.outline-purple-700]="user?.type === UserType.CARRIER"
[class.outline-gray-700]="user?.type === UserType.AUTHORITY"></div> -->
<!-- <div class="h-12"></div> -->
<div class="fixed bottom-0 left-0 right-0 z-[100]">
  <div
    class="text-white py-2 px-6 flex flex-row justify-between items-center">
    <!-- [class.bg-primary]="!authService.user"
        [class.bg-secondary]="
          (authService.user$ | async)?.type === UserType.CONSUMER
        "
    [class.bg-accent]="(authService.user$ | async)?.type === UserType.AGENT"
        [class.bg-success]="
          (authService.user$ | async)?.type === UserType.LEADSELLER
        " -->
    <div>
      <h5>
        enrollhere DEMO
        @if (user?.type === UserType.CONSUMER) {
          <span> as Consumer </span>
        }
        @if (user?.type === UserType.AGENT) {
          <span> as Agent </span>
        }
        @if (user?.type === UserType.LEADSELLER) {
          <span>
            as Marketing Vendor
          </span>
        }
        @if (user?.type === UserType.CARRIER) {
          <span>
            as Insurance Carrier
          </span>
        }
        @if (user?.type === UserType.AGENCY) {
          <span> as Agency </span>
        }
        @if (user?.type === UserType.AUTHORITY) {
          <span> as CMS </span>
        }
      </h5>
      <p style="font-size: 6pt">{{ version }}</p>
    </div>
    @if (openerWindow) {
      <button
        class="btn btn-sm btn-neutral"
        (click)="switchRole()">
        <fa-icon [icon]="faPersonWalkingArrowLoopLeft"></fa-icon> Switch Role
      </button>
    }
  </div>
</div>
}
