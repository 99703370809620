<app-modal #modal [wide]="true" (dismissed)="userId = undefined">
  @if (userId) {
    @if (editAmount) {
      <div class="mb-4">
        <app-payout-cycle-amount-change-edit
          [amountChanges]="editAmount.amountChanges"
          [payoutsMerged]="editAmount.payoutsMerged"
          [agencyId]="editAmount.agencyId"
          (valueChanged)="valueChanged.emit($event)"
        ></app-payout-cycle-amount-change-edit>
      </div>
    }

    @if (balances) {
      <div class="mb-4">
        <app-account-balances-detail
          [accounts]="balances.accounts"
          [balances]="balances.balances"
          [userId]="userId"
        ></app-account-balances-detail>
      </div>
    }

    <app-payouts-overview
      [isModal]="true"
      [hideAddPayoutButton]="true"
      [accounts]="accounts"
      [filterUserId]="userId"
      [cycleId]="cycleId"
      [cycles]="cycles"
      [identifierGroupId]="identifierGroupId"
      [policyId]="policyId"
      [agencyFilterType]="agencyFilterType"
      [showAllCycles]="showAllCycles"
    ></app-payouts-overview>
  }
</app-modal>
