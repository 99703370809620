import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PermissionGrantedActions } from './permission-granted.actions';
import {
  Firestore,
  collection,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { User_Permission, userTypeToJSON } from '../../../models';
import { getUserCollection } from 'src/app/shared/misc/getUserCollection';
import { UserCacheService } from 'src/app/shared/services/user-cache.service';

@Injectable()
export class PermissionGrantedEffects {
  private actions$ = inject(Actions);
  private firestore = inject(Firestore);
  private userCacheService = inject(UserCacheService);

  loadPermissionsGranted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionGrantedActions.loadPermissionsGranted),
      mergeMap(({ userId, userType, grantedUserType }) => {
        return new Observable<Action>(subscriber => {
          console.log('loadPermissionsGranted', {
            userId,
            userType,
            grantedUserType,
          });

          let collectionName = 'users';
          if (grantedUserType) {
            const collectionNameString = getUserCollection(grantedUserType);
            if (collectionNameString) {
              collectionName = collectionNameString;
            }
          }
          // let collectionName = 'users';
          // switch (userType) {
          //   case UserType.CONSUMER:
          //     collectionName = 'consumers';
          //     break;
          //   case UserType.AGENT:
          //     collectionName = 'agents';
          //     break;
          // }

          // const unsubscribe = onSnapshot(
          //   doc(
          //     this.firestore,
          //     'users',
          //     userId,
          //     'private',
          //     'permissionsGranted'
          //   ),
          //   snapshot => {
          //     if (!snapshot.exists()) {
          //       subscriber.next(
          //         PermissionGrantedActions.loadPermissionsGrantedSuccess({
          //           permissionsGranted: [],
          //         })
          //       );
          //       return;
          //     }
          //     const permissionsGranted = User_PermissionContainer.fromJSON(
          //       snapshot.data()
          //     );

          //     const permissionsGrantedList: User_Permission[] = [];
          //     for (const i in permissionsGranted.permissions) {
          //       permissionsGrantedList.push({
          //         ...permissionsGranted.permissions[i],
          //         user: {
          //           ...permissionsGranted.permissions[i].user,
          //           id: i,
          //         },
          //       });
          //     }
          //     subscriber.next(
          //       PermissionGrantedActions.loadPermissionsGrantedSuccess({
          //         permissionsGranted: permissionsGrantedList,
          //       })
          //     );
          //   },
          //   error => {
          //     subscriber.next(
          //       PermissionGrantedActions.loadPermissionsGrantedFailure({
          //         error,
          //       })
          //     );
          //   }
          // );

          let ref = query(
            collection(
              this.firestore,
              collectionName,
              userId,
              'private',
              'permissions',
              'received'
            )
          );

          if (userType) {
            ref = query(ref, where('userType', '==', userTypeToJSON(userType)));
          }

          const unsubscribe = onSnapshot(
            ref,
            snapshot => {
              if (snapshot.empty) {
                subscriber.next(
                  PermissionGrantedActions.loadPermissionsGrantedSuccess({
                    permissionsGranted: [],
                  })
                );
                return;
              }

              const permissionList = snapshot.docs.map(doc => {
                const data = doc.data();
                // this.userCacheService.addToCache(doc.id, data['user']);
                this.userCacheService.addToCache(doc.id);

                return User_Permission.fromJSON({
                  ...data,
                  userId: doc.id,
                  grantedUserId: userId,
                  grantedUserType,
                });
              });

              subscriber.next(
                PermissionGrantedActions.loadPermissionsGrantedSuccess({
                  permissionsGranted: permissionList,
                })
              );
            },
            error => {
              console.log('error', error);
              subscriber.next(
                PermissionGrantedActions.loadPermissionsGrantedFailure({
                  error,
                })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          // return unsubscribe;
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({
              type: '[PermissionGranted API] Load permissionsGranted Error',
              error,
            })
          )
        );
      })
    );
  });
}
