import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import packageJson from '../../../../../../package.json';
import { RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';

import { UserType } from 'src/app/shared/models';
import { AuthService, UserInfo } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-theme-dev',
  templateUrl: './theme-dev.component.html',
  styleUrls: ['./theme-dev.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class ThemeDevComponent implements OnInit {
  private cdr = inject(ChangeDetectorRef);
  private authService = inject(AuthService);

  production = environment.production;
  demo = environment.isDemo;
  version = environment.production
    ? packageJson.version
    : environment.devVersion;

  user: UserInfo | null = null;

  UserType = UserType;

  ngOnInit() {
    if (!environment.production) {
      this.authService.user$.subscribe((user) => {
        this.user = user;
        this.cdr.detectChanges();
      });
    }
  }
}
