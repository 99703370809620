import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AccountActions } from './account.actions';
import {
  DocumentData,
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { CreditAccount, UserType } from 'src/app/shared/models';
import { Action } from '@ngrx/store';
import { getUserCollection } from 'src/app/shared/misc/getUserCollection';
import {
  CARRY_FORWARD_ACCOUNT_ID,
  getDefaultCarryForwardAccount,
} from 'src/app/shared/data/constants';
import { UserCacheService } from 'src/app/shared/services/user-cache.service';

@Injectable()
export class AccountEffects {
  private actions$ = inject(Actions);
  private firestore = inject(Firestore);
  private userCacheService = inject(UserCacheService);

  loadAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loadAccounts),
      mergeMap(({ agencyId, userType }) => {
        console.info('loadAccounts$', { agencyId, userType });

        const userCollection = userType
          ? (getUserCollection(userType) ?? 'agencies')
          : 'agencies';

        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(
              this.firestore,
              userCollection,
              agencyId,
              'commissionCreditAccounts',
            ),
            async (snapshot) => {
              let agency;
              if (this.userCacheService.cache[agencyId]) {
                agency = this.userCacheService.cache[agencyId];
              } else {
                const agencyResponse = await this.userCacheService.loadUsers([
                  agencyId,
                ]);
                agency = agencyResponse.users[0];
              }

              const accounts = snapshot.docs.map((doc) =>
                CreditAccount.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  owner: {
                    id: agencyId,
                    name: agency?.name,
                    type: UserType.AGENCY,
                  },
                }),
              );

              console.info('accounts', accounts);

              if (!accounts.find((x) => x.id === CARRY_FORWARD_ACCOUNT_ID)) {
                accounts.push(getDefaultCarryForwardAccount(agencyId));
              }

              subscriber.next(
                AccountActions.loadAccountsSuccess({ accounts, agencyId }),
              );
            },
            (error) => {
              console.log('loadAccounts$ error', error);
              // subscriber.next(AccountActions.loadAccountsFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Account API] Load Accounts Error', error }),
          ),
        );
      }),
    );
  });

  loadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loadAccount),
      mergeMap(
        ({ agencyId, accountId, userType }) =>
          new Observable<Action>((subscriber) => {
            const userCollection = userType
              ? (getUserCollection(userType) ?? 'agencies')
              : 'agencies';

            const unsubscribe = onSnapshot(
              doc(
                this.firestore,
                userCollection,
                agencyId,
                'commissionCreditAccounts',
                accountId,
              ),
              (snapshot) => {
                if (!snapshot.exists()) {
                  console.log('account not found', { accountId, agencyId });
                  subscriber.next(
                    AccountActions.loadAccountFailure({
                      error: 'Account not found',
                    }),
                  );
                  return;
                }
                const account = CreditAccount.fromJSON({
                  ...snapshot.data(),
                  id: snapshot.id,
                  owner: {
                    id: agencyId,
                    type: UserType.AGENCY,
                  },
                });
                subscriber.next(
                  AccountActions.loadAccountSuccess({ account, agencyId }),
                );
              },
              (error) => {
                subscriber.next(AccountActions.loadAccountFailure({ error }));
              },
            );
            return unsubscribe;
          }),
      ),
    );
  });

  addAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.addAccount),
      mergeMap(async ({ account, agencyId, userType }) => {
        try {
          const userCollection = userType
            ? (getUserCollection(userType) ?? 'agencies')
            : 'agencies';

          const accountDoc = CreditAccount.toJSON(account);

          const docRef = await addDoc(
            collection(
              this.firestore,
              userCollection,
              agencyId,
              'commissionCreditAccounts',
            ),
            accountDoc,
          );
          return AccountActions.addAccountSuccess({
            account: CreditAccount.fromJSON({ ...account, id: docRef.id }),
            agencyId,
          }); // return new account with id
        } catch (error) {
          return AccountActions.addAccountFailure({ error });
        }
      }),
    );
  });

  removeAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.removeAccount),
      mergeMap(async ({ agencyId, accountId, userType }) => {
        try {
          const userCollection = userType
            ? (getUserCollection(userType) ?? 'agencies')
            : 'agencies';

          await deleteDoc(
            doc(
              this.firestore,
              userCollection,
              agencyId,
              'commissionCreditAccounts',
              accountId,
            ),
          );
          return AccountActions.removeAccountSuccess({ accountId, agencyId }); // return removed account's id
        } catch (error) {
          return AccountActions.removeAccountFailure({ error });
        }
      }),
    );
  });

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.updateAccount),
      mergeMap(async ({ agencyId, accountId, account, userType }) => {
        try {
          console.log('updateAccount$', { agencyId, accountId, account });
          const userCollection = userType
            ? (getUserCollection(userType) ?? 'agencies')
            : 'agencies';

          const accountDoc = CreditAccount.toJSON(account);

          await setDoc(
            doc(
              this.firestore,
              userCollection,
              agencyId,
              'commissionCreditAccounts',
              accountId,
            ),
            accountDoc as DocumentData,
            {
              merge: true,
            },
          );
          return AccountActions.updateAccountSuccess({
            agencyId,
            accountId,
            account,
          }); // return updated account's id and changes
        } catch (error) {
          console.log('updateAccount$ error', error);
          return AccountActions.updateAccountFailure({ error });
        }
      }),
    );
  });
}
